<template>
  <Layout name="Default">
    <div class="Settings">
      <router-view />
    </div>
  </Layout>
</template>

<script>
import Layout from '@hypefactors/shared/js/router/Layout.js'

export default {
  name: 'Settings',

  components: {
    Layout
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';

.Settings {
  &__Children {
    padding: $padding $padding-small;
    background: $hf__color-white;
  }
}
</style>
